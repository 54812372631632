<template>
  <!-- <div> -->
    <router-view />
  <!-- </div> -->
</template>

<script>
export default {
  name: 'Blank',
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
</style>

